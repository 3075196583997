<template>
    <section :class="[namespace(), skinStyle]">
        <div :class="[namespace('layout-grid')]">
            <div :class="namespace('data-table')">
                <markets-ui-data-table
                    :skin="watchedSettings.skin"
                    size="medium"
                    :header-configs="headerConfigs"
                    :row-data="highlightRowData"
                />
            </div>
            <div :class="namespace('content-wrapper')">
                <markets-ui-menus
                    :skin="skin"
                    :data-model="groupMenuLabels"
                    @change="_changeTabType"
                >
                </markets-ui-menus>
                <div>
                    <article :class="namespace('tab-article')">
                        <div :class="namespace('tab-article__content')">
                            <p>
                                {{ tabContent }}
                            </p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
const HIGHLIGHTS_TABS = {
    quantitativeValuation: 'quantitativeValuation',
    economicMoat: 'economicMoat',
    operationRatios: 'operationRatios'
};
const { utils, mixins } = mwcMarketsCore;
import {
    getHighlightsBasicInfoDataPoints,
    getHighlightsRows
} from '../metadata/data-point';
export default {
    name: 'research-highlights',
    mixins: [mixins.componentUI],
    props: {
        skin: {
            type: String,
            default: ''
        },
        /**
         * Highlights data model, it must have basic info and smart text info.
         */
        dataModel: {
            type: Object,
            default: function() {
                return {
                    basicInfo: null,
                    smartTextInfo: null
                };
            }
        },
        /**
         * The labels, come from research ui component.
         */
        initedLabels: {
            type: Object
        }
    },
    /**
     *  The data will hold current tab string.
     * @returns {{currentTab: string}}
     */
    data() {
        return {
            currentTab: HIGHLIGHTS_TABS.quantitativeValuation,
            headerConfigs: [
                {
                    fieldName: 'name',
                    text: 'Index',
                    width: '40%'
                },
                {
                    fieldName: 'value',
                    text: 'Index',

                    width: '30%'
                },
                {
                    fieldName: 'date',
                    text: 'Index',
                    align: 'right',
                    width: '30%'
                }
            ]
        };
    },
    created() {
        this.namespace = utils.namespace('research-highlights');
    },
    computed: {
        skinStyle() {
            const skinSuffix = utils.getSkinSuffix(this.skin);
            return `${this.namespace()}--${skinSuffix}`;
        },
        // the smart text group menu.
        groupMenuLabels() {
            return Object.keys(HIGHLIGHTS_TABS).map(name => {
                return {
                    id: HIGHLIGHTS_TABS[name],
                    name: this.initedLabels[name],
                    selected: this.currentTab === HIGHLIGHTS_TABS[name]
                };
            });
        },

        highlightRowData() {
            const basicInfo = this.dataModel.basicInfo;
            if (!basicInfo) {
                return [];
            }
            const rows = getHighlightsRows();
            const basicInfoData = {};
            const basicInfoDataPoints = getHighlightsBasicInfoDataPoints();
            basicInfoDataPoints.map(point => {
                basicInfoData[point.id] = utils.getFormatValue({
                    dataType: point.dataType,
                    value: basicInfo[point.id]
                });
            });
            return rows.map(dataPoint => {
                return {
                    id: dataPoint,
                    name: this.initedLabels[dataPoint],
                    value: this.getHighlightValueByDataPoint(
                        basicInfoData,
                        dataPoint
                    ),
                    date:
                        dataPoint === 'priceCompareFairValue'
                            ? basicInfoData.tradeTime
                            : basicInfoData.updateDate
                };
            });
        },
        /**
         * Highlights basic info data, just basic info need to formatter.
         */
        highlightsBasicInfoData() {
            const basicInfoDataPoints = getHighlightsBasicInfoDataPoints();
            const basicInfo = this.dataModel.basicInfo;
            const basicInfoData = {};
            basicInfoDataPoints.map(point => {
                basicInfoData[point.id] = utils.getFormatValue({
                    dataType: point.dataType,
                    value: basicInfo ? basicInfo[point.id] : null
                });
            });
            return { basicInfoData };
        },
        /**
         * Highlights smart text info.
         * @returns {string} - Component content.
         */
        tabContent() {
            const smartTextInfo = this.dataModel.smartTextInfo;
            let tab;
            switch (this.currentTab) {
                case HIGHLIGHTS_TABS.quantitativeValuation: {
                    tab = HIGHLIGHTS_TABS.quantitativeValuation;
                    break;
                }
                case HIGHLIGHTS_TABS.economicMoat: {
                    tab = HIGHLIGHTS_TABS.economicMoat;
                    break;
                }
                case HIGHLIGHTS_TABS.operationRatios: {
                    tab = HIGHLIGHTS_TABS.operationRatios;
                    break;
                }
            }
            return utils.getFormatValue({
                dataType: 'string',
                value: smartTextInfo ? smartTextInfo[tab] : null
            });
        },
        /**
         * Compute price difference by last price and fair value.
         */
        priceDifferenceAndColor() {
            const basicInfo = this.dataModel.basicInfo;
            let value = utils.getFormatValue({
                dataType: 'number',
                value: basicInfo
                    ? basicInfo.lastPrice - basicInfo.fairValue
                    : null
            });
            // if the actual price is above fair value,
            // it is not in favor for buyer as it's over priced and that's why show red.
            // vice versa for the green indicator.
            // red for actual above fair value.
            // green for actual below fair value.
            let color;
            if (value > 0) {
                color = 'negative';
                value = `+${value}`;
            } else if (value < 0) {
                color = 'positive';
            } else {
                color = 'neutral';
            }
            return {
                color,
                value
            };
        }
    },
    methods: {
        /**
         * The group menu change type callback.
         * When the tab change, the content need recomputed.
         * @param item - Tab id.
         * @private
         */
        _changeTabType(item) {
            if (item) {
                this.currentTab = item.id;
            }
        },
        getHighlightValueByDataPoint(data, dataPoint) {
            let value;
            if (dataPoint === 'priceCompareFairValue') {
                value = `<span class="${this.namespace('price-fair-value')}">$${
                    data.lastPrice
                }/${data.fairValue}</span> <span class="${this.namespace(
                    'price-diff'
                )} ${this.namespace(this.priceDifferenceAndColor.color)}">$${
                    this.priceDifferenceAndColor.value
                }</span>`;
            } else if (dataPoint === 'rating') {
                if (+data.rating === 0) {
                    value = 'Not Rated';
                } else if (+data.rating === 6) {
                    value = 'Under Review';
                } else {
                    if (data.isMomentumFilterFlag === 'true') {
                        value = `<markets-ui-momentum-star-rating value="${+data.rating}"/>`;
                    } else {
                        value = `<markets-ui-star-rating value="${+data.rating}"/>`;
                    }
                }
            } else {
                value = data[dataPoint];
            }
            return value;
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';

$namespace: 'mwc-markets-research-highlights';
.#{$namespace} {
    .mds-th__inner___markets {
        display: none;
    }
    &-layout-grid {
        width: 100%;
        min-width: 350px;
        display: flex;
        flex-wrap: wrap;
    }

    &-price-fair-value {
        margin-right: $mds-space-1-x;
    }
    &-price-diff {
        font-weight: bold;
    }
    &-negative {
        color: $mds-text-color-performance-negative;
    }
    &-positive {
        color: $mds-text-color-performance-positive;
    }
    &-neutral {
        color: $mds-text-color-performance-neutral;
    }
    &-content-wrapper {
        width: calc((100% - 32px) / 2);
        padding: $mds-space-1-x;
        flex-grow: 1;
        min-width: 350px;
    }
    &-data-table {
        width: calc((100% - 32px) / 2);
        padding: $mds-space-1-x;
        flex-grow: 1;
    }
    &-tab-article {
        align-items: flex-start;
    }
    &-tab-article__content {
        margin-top: $mds-space-1-x;
        padding: $mds-space-1-x;
        min-width: 300px;
        p {
            font-size: 16px;
        }
    }

    .markets-ui-menus {
        padding-left: $mds-space-half-x;
    }
    &--dark-gray {
        color: $mds-text-color-primary-on-dark;
        .mds-star-rating___markets .mds-icon___markets {
            fill: $mds-text-color-primary-on-dark;
        }
    }
}
</style>
